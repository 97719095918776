import { useEffect } from 'react';
import { Paper, TextField, Box, Typography } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import {
  SummaryField,
  SummaryValue,
  useCalculatedSummary,
} from './hooks/useCalculatedSummary';

export type SummaryUIOptions = {
  sectionHeader: string;
  sectionDescription: string;
  summaryFields: Record<string, SummaryField>;
};

export const CalculatedSummary = (
  props: FieldExtensionComponentProps<SummaryValue, SummaryUIOptions>,
) => {
  const { onChange, formContext, uiSchema } = props;
  const uiOptions = uiSchema['ui:options'];
  const summaryFieldsConfig = uiOptions?.summaryFields;
  const sectionHeader = uiOptions?.sectionHeader || '';
  const sectionDescription = uiOptions?.sectionDescription || '';

  const [fields, summaryValue] = useCalculatedSummary(summaryFieldsConfig, {
    form: formContext.formData,
  });

  useEffect(() => {
    onChange(summaryValue);
  }, [onChange, summaryValue]);

  return (
    <Paper elevation={4}>
      <Box
        sx={{
          display: 'inline-flex',
          padding: '16px',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5">{sectionHeader}</Typography>
        <Typography variant="subtitle1">{sectionDescription}</Typography>
        {fields.map(field => {
          return (
            <TextField
              key={field.name}
              name={field.title}
              label={field.title}
              type="text"
              InputLabelProps={{ shrink: true }}
              helperText={field.description}
              disabled
              value={field.value}
              margin="normal"
              error={field.error}
              inputProps={{ autoFocus: false, maxLength: field.maxLength }}
              FormHelperTextProps={{
                margin: 'dense',
                style: { marginLeft: 0 },
              }}
            />
          );
        })}
      </Box>
    </Paper>
  );
};
