import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

export const useDebounce = (callback: Function, timeout = 500) => {
  const ref = useRef<Function>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return debouncedCallback;
};
