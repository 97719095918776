import { Grid } from '@material-ui/core';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderLayout,
  LayoutTemplate,
} from '@backstage/plugin-scaffolder-react';

const WithSummaryStep: LayoutTemplate = props => {
  const { properties, description, title, uiSchema } = props;

  const summaryFieldNames = Object.entries(uiSchema || {})
    .filter(([_, value]) => value['ui:field'] === 'CalculatedSummary')
    .map(([key]) => key);

  const commonProperties = properties.filter(
    p => !summaryFieldNames.includes(p.name),
  );
  const summaryProperties = properties.filter(p =>
    summaryFieldNames.includes(p.name),
  );

  return (
    <>
      <h1>{title}</h1>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid xs={6} container direction="column" spacing={1}>
          {commonProperties.map(prop => (
            <Grid item key={prop.content.key}>
              {prop.content}
            </Grid>
          ))}
        </Grid>
        <Grid xs={6} container direction="column" spacing={1}>
          {summaryProperties.map(prop => (
            <Grid item key={prop.content.key}>
              {prop.content}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {description}
    </>
  );
};

export const WithSummaryStepLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'WithSummaryStepLayout',
    component: WithSummaryStep,
  }),
);
