import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { CalculatedSummary } from './CalculatedSummaryExtension';
import { summaryValueValidator } from './validator';

export const CalculatedSummaryExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CalculatedSummary',
    component: CalculatedSummary,
    validation: summaryValueValidator,
  }),
);
