import { SignInPageProps } from '@backstage/core-app-api';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import { getAvailableIdentityProviders } from './availableIdentityProvidersResolver';

export const ConfigBasedSignInPage = (props: SignInPageProps) => {
  const config = useApi(configApiRef);
  const providers = getAvailableIdentityProviders(config);

  return <SignInPage {...props} auto providers={providers} />;
};
