import { useEffect, useMemo, useState } from 'react';
import template from 'lodash/template';

import { useDebounce } from './useDebounce';

export type SummaryField = {
  title: string;
  name?: string;
  description: string;
  calculatedTemplate?: string;
  maxLength?: number;
  value?: string;
  error: boolean;
};

export type SummaryValue = Record<string, any>;
export type SummaryData = {
  form: Record<string, any>;
};

export const useCalculatedSummary = (
  summaryFields: Record<string, SummaryField> | undefined,
  data: SummaryData,
): [fields: SummaryField[], value: SummaryValue] => {
  const templateFields = useMemo(
    () =>
      Object.entries(summaryFields || {}).map(([name, field]) => ({
        name,
        ...field,
        applyTemplate: template(field.calculatedTemplate || ''),
      })),
    [summaryFields],
  );

  const [fields, setFields] = useState<SummaryField[]>([]);

  const updateFields = useDebounce(() => {
    const newFields: SummaryField[] = templateFields.map(field => {
      const value = field.applyTemplate(data);
      return {
        value,
        name: field.name,
        title: field.title,
        description: field.description,
        maxLength: field.maxLength,
        error: field.maxLength ? value.length > field.maxLength : false,
      };
    });
    setFields(newFields);
  });

  useEffect(() => {
    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const value = Object.fromEntries(fields.map(f => [f.title, f.value]));

  return [fields, value];
};
