import { CustomFieldValidator } from '@backstage/plugin-scaffolder-react/index';
import { SummaryValue } from './hooks/useCalculatedSummary';
import { SummaryUIOptions } from './CalculatedSummaryExtension';

export const summaryValueValidator: CustomFieldValidator<
  SummaryValue,
  SummaryUIOptions
> = (data, field, context) => {
  const fields = context.uiSchema?.['ui:options']?.summaryFields || [];
  const fieldsConfig = Object.entries(fields).map(([_, f]) => {
    return f;
  });

  Object.entries(data).forEach(([title, value]) => {
    const fieldConfig = fieldsConfig.find(f => f.title === title);
    const maxLength = fieldConfig?.maxLength;

    if (maxLength && value.length > maxLength) {
      field.addError(`${title} length can not be more than ${maxLength}`);
    }
  });
};
